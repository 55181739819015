import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/contact.css" 

const Contact = () => (
    <Layout>
        <SEO title="Contact" keywords={[`email`, `contact`, `akinul.com`,]}/>
        <div>
            <form 
                name="contact" 
                method="post" 
                data-netlify="true" 
                data-netlify-honeypot="bot-field"
                action="/success/"
                // action="https://formspree.io/akinul.cs@gmail.com"
                className="contact-form-style"
            >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" /> 
                <div><p>Contact Form</p></div>
                <div>
                    <input type="text" name="name" className="field-style field-split align-left" placeholder="Name" required />
                    <input type="email" name="email" className="field-style field-split align-right" placeholder="Email" required />
                </div>
                <div>
                    <input type="text" name="subject" className="field-style field-full align-none" placeholder="Subject" required />
                </div>
                <div>
                    <textarea name="message" className="field-style" placeholder="Message" required></textarea>
                </div>
                <div>
                    <button type="submit">Send Message</button>
                    <button type="reset">Clear All</button>
                </div>
            </form>
        </div>
    </Layout>
)

export default Contact

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony netlify